.alert-button-wrapper {
    position: relative;
    display: inline-block;
}

.alert-button {
    background-color: #ccc;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 50%;
}

.alert-button.alert-active {
    background-color: var(--sw-main-accent-fill-color);
    color: var(--sw-text-color);
}

.alert-button:hover {
    background-color: #aaa;
}

.tooltip {
    position: absolute;
    top: 50%; /* Vertically centered relative to the button */
    left: 100%; /* Position the tooltip to the right of the button */
    transform: translateY(-50%); /* Vertically center it */
    background-color: #D93025; /* Red background */
    color: white;
    padding: 8px 16px;
    border-radius: 24px; /* Rounded corners */
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}


.notification-symbol {
    width: 27px;
    height: 30px;
}