.current-weather-card {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    max-width: 20rem;
    width: 100%;
    color: var(--sw-text-color);
}

.current-weather-icon {
    display: flex;
    position: relative;
}

.current-weather-temp-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.current-weather-temp {
    font-size: 50px;
}

.current-weather-high-low {
    display: flex;
    flex-direction: column;
    color: var(--sw-secondary-text-color);
}

.current-weather-real-feel {
    color: var(--sw-secondary-text-color);
}

.current-weather-condition {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin-top: 10px;
    color: var(--sw-text-color);
}

.current-weather-details {
    border-radius: 25px;
    padding: 1rem;
    min-width: 9rem;
    min-height: 9rem;
}

.current-weather-details.filled {
    background-color: var(--sw-main-fill-color);
}

.current-weather-details.unfilled {
    border: .1rem solid var(--sw-border-color);
}