/* Overlay */
.location-search-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.location-search-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: .5rem;
    background-color: var(--sw-modal-background-color);
    color: var(--sw-text-color);
    padding: 2rem 0.5rem;
    border-radius: 15px;
    text-align: center;
    width: 90%;
    height: 30rem;
    max-width: 500px;
}

.location-search-modal-title {
    padding: 0 3rem;
}

.location-search-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
}

/* Current Location Button */
.current-location-button {
    background-color: var(--sw-main-accent-fill-color);
    border: none;
    border-radius: 50%;
    padding: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    outline: none;
}

.current-location-button:hover {
    background-color: var(--sw-main-accent-fill-color-darkened);
}