.weather-metrics-panel-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: .8rem;
}

.weather-metrics-grid-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(7rem, auto));
    gap: .5rem;
    justify-content: center;
    transition: max-height 0.3s ease-in-out;
    margin: 0 0.5rem;
}

.weather-metrics-grid-container.is-small-screen {
    grid-template-columns: repeat(2, minmax(7rem, auto));
}

.weather-metrics-grid-container.expanded {
    max-height: 30rem; 
}

.weather-metrics-grid-container.collapsed {
    max-height: 8rem; 
    overflow: hidden;
}

/* Button Styles */
.weather-metrics-toggle-button {
    background: none;
    color: var(--sw-text-colors);
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    padding: 5px 10px;
}

/* Hide the button on larger screens */
@media screen and (min-width: 769px) {
    .weather-metrics-toggle-button {
        display: none;
    }
}