.hourly-weather-card-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scrollbar-width: none;
}

.hourly-weather-card-wrapper:hover {
    scrollbar-width: auto;
}

.hourly-weather-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    min-width: 100px;
    height: 200px;
    color: var(--sw-text-color);
    margin: .8rem .8rem .8rem 0;
}

.hourly-weather-card.filled {
    background-color: var(--sw-main-fill-color);
}

.hourly-weather-card.unfilled {
    border: .1rem solid var(--sw-border-color)
}

.hourly-weather-hour {
    font-size: 1.5rem;
    text-transform: lowercase;
    margin: .5rem 0;
}

.hourly-weather-condition {
    margin: 0 0;
    text-transform: capitalize;
    color: var(--sw-secondary-text-color);
}

.hourly-weather-temp {
    font-size: 2rem;
    margin: .5rem 0;
}

.hourly-weather-icon {
    position: relative;
    width: 50px;
    height: 50px;
}

/* TODO: Delete this after replacing with SVGs */
.sun-cloud .sun {
    width: 25px;
    height: 25px;
    background: orange;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 10px;
}