.title {
    font-size: 1.5rem;
    color: var(--sw-text-color);
    margin: 0 .5rem;
}

.header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
}

.header-title-and-location-name {
    display: flex;
    flex-direction: column;
}

.menu-and-alert-buttons {
    gap: .8rem;
    display: flex;
    flex-direction: column;
}