/* Styling for LocationTag */
.location-name-tag {
  color: var(--sw-text-color);
  font-size: .8rem;
  padding: .2rem 1.5rem;
  border-radius: 25px; /* Makes it pill-shaped */
  text-align: center;
}

.location-name-tag.filled {
  background-color: var(--sw-main-fill-color);
}

.location-name-tag.unfilled {
  border: .1rem solid var(--sw-border-color);
} 