.minute-card {
    border-radius: 25px;
    padding: 1rem 1.5rem 3rem 1.5rem;
    color: var(--sw-text-color);
    max-width: 400px;
    margin-bottom: .8rem;
}

.minute-card.filled {
    background-color: var(--sw-main-fill-color);
}

.minute-card.unfilled {
    border: .1rem solid var(--sw-border-color)
}

.minute-card h3 {
    font-size: 1.2rem;
    text-align: left;
    margin: 0;
}

.minute-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: .4rem;
    position: relative; /* Allow label positioning relative to this bar */
}

.bar {
    width: 100%;
    transition: height 0.3s ease;
    border-radius: 25px;
    width: .2rem;
    background-color: var(--sw-text-color);
}

.time-label {
    position: absolute;
    bottom: -45px;
    font-size: 0.8rem;
    color: var(--sw-text-color);
    text-align: center;
}

.timeline {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 1rem;
}
  