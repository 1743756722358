/* Modal Background */
.alert-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    animation: fadeIn 0.3s forwards;
    overflow: auto; 
}

/* Modal Content */
.alert-modal-content {
    color: var(--sw-text-color);
    background-color: var(--sw-modal-background-color);
    padding: 20px;
    border-radius: 25px;
    width: 80%;
    max-width: 500px;
    max-height: 90vh; 
    overflow-y: auto; 
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Headings in Modal */
.alert-modal-content h2 {
    margin-bottom: 10px;
}

/* Button Styles */
.alert-modal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--sw-main-accent-fill-color);
    color: var(--sw-text-color);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.alert-modal-content button:hover {
    background-color: var(--sw-main-accent-fill-color-darkened);
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
