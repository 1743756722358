.weather-metric-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 25px;
    padding: 10px;
    text-align: center;
    color: #ffffff;
}

.weather-metric-card.filled {
    background-color: var(--sw-secondary-fill-color);
}

.weather-metric-card.unfilled {
    border: .1rem solid var(--sw-border-color);
}

.weather-metric-icon {
    width: 40px;
    height: 40px;
}

.weather-metric-value {
    font-size: 20px;
    font-weight: bold;
    color: var(--sw-text-color);
}

.weather-metric-name {
    color: var(--sw-secondary-text-color); /* Lighter text color for the name */
}