.location-menu-button {
    background: var(--sw-modal-button-color);
    z-index: 10; /* Keep above menu container */
    border: none;
    color: var(--sw-modal-button-accent-color);
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.location-menu-button:hover {
    transform: scale(1.1);
}

.location-menu-button.open {
    transform: rotate(90deg);
    border-color: #fff;
    border-style: solid;
    border-width: .1rem;
}

.location-menu-button-icon {
    font-size: 28px;
}

.location-menu-button-icon.close {
    font-size: 36px;
} 