.location-menu-container {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 0;
    height: 0;
    background: var(--sw-modal-background-color);
    border-radius: 50%;
    overflow: hidden;
    transition: all 0.4s ease;
    z-index: 1; /* Ensure it's below the MenuButton */
    min-width: 20rem;
}

.location-menu-container.location-menu-open {
    width: 20%;
    height: 80%;
    border-radius: 25px;
    z-index: 5; /* Higher when open to cover content but not the MenuButton */
}

.location-menu-content {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 10px;
    padding: 10px;
}

.location-menu-item {
    background: #356bb7;
    border-radius: 12px;
    padding: 10px;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.location-menu-plus-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background-color: #007bff; /* Blue color */
    color: white;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.location-menu-plus-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.location-menu-plus-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Accessibility focus ring */
}